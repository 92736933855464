<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">指标配置管理</h1>
    </div>
    <a-card :bordered="false">
      <a-row :gutter="12">
        <a-col :span="4">
           <typeTree :sup_this="sup_this"></typeTree>
        </a-col>
        <a-col :span="20">
          <div class="fs-search-box">
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-form-item label="中文名称">
                  <a-input v-model="searchParams.cnName" placeholder="请输入中文名称" allowClear />
                </a-form-item>
                <a-form-item label="英文名称">
                  <a-input v-model="searchParams.enName" placeholder="请输入英文名称" allowClear />
                </a-form-item>
                <a-form-item label="指标编码">
                  <a-input v-model="searchParams.code" placeholder="请输入指标编码" allowClear />
                </a-form-item>
                <a-form-item class="f-r m-r-none">
                  <a-button  icon="plus" @click="handleAdd" type="primary">新增</a-button>
                  <a-button class="m-l-6" type="primary" @click="search" icon="search">查询</a-button>
                  <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
          <a-table
            @change="tableChange"
            size="middle"
            :columns="columns"
            :dataSource="tableData"
            :pagination="pagination"
            :rowKey="(record) => record.id"
            bordered
          >
            <template slot="_index" slot-scope="text, record, index">
              {{ searchParams.number * searchParams.size + index + 1 }}
            </template>
            <template slot="metricsType" slot-scope="metricsType">
              <span v-if="metricsType === 'yc'">遥测</span>
              <span v-if="metricsType === 'yx'">遥信</span>
              <span v-if="metricsType === 'yt'">遥调</span>
              <span v-if="metricsType === 'yk'">遥控</span>
            </template>
            <template slot="core" slot-scope="core">
              {{ core ? '是' : '否' }}
            </template>
            <template slot="unit" slot-scope="record">
              {{ record ? record : '-' }}
            </template>
            <template slot="maxValue" slot-scope="record">
              {{ record ? record : '-' }}
            </template>
            <template slot="minValue" slot-scope="record">
              {{ record !== null ? record : '-' }}
            </template>
            <template slot="escapeContent" slot-scope="escapeContent">
          <span v-if="escapeContent">
          <a-tag v-if="JSON.parse(escapeContent).zero"> 0: {{JSON.parse(escapeContent).zero}}</a-tag>
            <span v-else>-</span>
          <a-tag v-if="JSON.parse(escapeContent).one">1:{{JSON.parse(escapeContent).one }}</a-tag>
             <span v-else>-</span>
            </span>
              <span v-else>--</span>
            </template>
            <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record.id)" v-auth="auth_equipment2.CONFIG_CREATE_AND_UPDATE">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete(record.id)" v-auth="auth_equipment2.CONFIG_DELETE">删除</a>
        </span>
          </a-table>
        </a-col>
      </a-row>
    </a-card>
    <a-modal v-model="addModal"  :destroyOnClose="true"  title="指标新增">
      <Form ref="addRef" type="add" :categoryList="categoryList" :defaultType="searchParams.typeId" @addSuccess="addSuccess" @addError="addError"></Form>
      <template slot="footer">
        <a-button :loading="addLoading" @click="handleAddOkWithContinue" type="primary" style="margin-right: 5px;"
        >保存新增
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOkWithCopy" type="primary" style="margin-right: 5px;"
        >保存复制
        </a-button>
        <a-button :loading="addLoading" @click="handleAddOk" type="primary" style="margin-right: 5px;"
        >保存关闭</a-button
        >
        <a-button @click="closeAddModal">取消</a-button>
      </template>
    </a-modal>
    <a-modal :visible="editModal" :destroyOnClose="true" @ok="handleEditOk" @cancel="closeEditModal" title="指标编辑">
      <Form
        ref="editRef"
        type="edit"
        :categoryList="categoryList"
        @editSuccess="editSuccess"
        @editError="editError"
      ></Form>
    </a-modal>
  </page-layout>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import Form from './Form'
  import entityCRUDWithCopy from '@/views/common/mixins/entityCRUDWithCopy'
  import PageLayout from '@/components/page/PageLayout'
  import { configColums } from './common/common'
  import typeTree from './typeTree/List'
  export default {
    components: { PageLayout, Form, typeTree },
    mixins: [entityCRUDWithCopy],
    name: 'localImageForm',
    data() {
      return {
        categoryId: null,
        sup_this: this,
        entityBaseUrl: SERVICE_URLS.equipment2.equipmentConfigApi,
        tableData: [],
        initColumns: configColums(),
        searchParams: {
          categoryId: undefined,
          cnName: '',
          code: '',
          enName: '',
          typeId: undefined
        },
        categoryList: [],
      }
    },
    created() {
      this.getCategoryTree()
    },
    watch: {
      'searchParams.typeId': function (val) {
        this.search()
      }
    },
    methods: {
      getCategoryTree() {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.equipmentTypeApi.search,
          noTips: true,
          success: (data) => {
            this.categoryList.push(data.body)
          },
        })
      },
    },
  }
</script>

<style scoped></style>
