export const configColums = () => {
  return [
    {
      title: '序号',
      width: '60px',
      scopedSlots: { customRender: '_index' },
      align: 'center',
    },
    {
      title: '指标中文名称',
      dataIndex: 'cnName',
      align: 'left',
    },
    {
      title: '指标英文名称',
      dataIndex: 'enName',
      align: 'left',
    },
    {
      title: '指标标识名',
      dataIndex: 'distinguishedName',
      align: 'left',
    },
    {
      title: '指标编码',
      dataIndex: 'code',
      align: 'center',
    },
    {
      title: '指标类型',
      dataIndex: 'metricsType',
      scopedSlots: { customRender: 'metricsType' },
      align: 'center',
    },
    {
      title: '转义',
      dataIndex: 'escapeContent',
      scopedSlots: { customRender: 'escapeContent' },
      align: 'center',
    },
    {
      title: '指标单位',
      dataIndex: 'unit',
      scopedSlots: { customRender: 'unit' },
      align: 'center',
    },
    {
      title: '指标最大值',
      dataIndex: 'maxValue',
      scopedSlots: { customRender: 'maxValue' },
      align: 'center',
    },
    {
      title: '指标最小值',
      dataIndex: 'minValue',
      scopedSlots: { customRender: 'minValue' },
      align: 'center',
    },
    {
      title: '核心指标',
      dataIndex: 'core',
      align: 'center',
      scopedSlots: { customRender: 'core' },
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ]
}
export const metricsType = () => {
  return [
    { value: 'yc', name: '遥测' },
    { value: 'yx', name: '遥信' },
    { value: 'yt', name: '遥调' },
    { value: 'yk', name: '遥控' },
  ]
}
